<template>
  <div class="title">分类</div>

  <pro-cate-list
    :list="list"
    :children="children"
    @handleclick="onClick"
    @handlesecclick="onClickSecond"
  ></pro-cate-list>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import ProCateList from "@/components/Product/List.vue";
import api from "@/api/axios";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "ProductCategory",
  components: {
    ProCateList,
  },
  setup() {
    const list = ref([]);
    const children = ref([]);

    api
      .get("/M/Product/getProductClassify")
      .then((res) => {
        list.value = res.data.obj.one;
        res.data.obj.two.unshift({
          id: 0,
          categoryCnname: "全部",
        });
        children.value = res.data.obj.two;
      })
      .catch((err) => {
        console.log(err);
      });
    const router = useRouter();
    function onClick(classid: string) {
      api
        .get("/M/Product/getProductClassify", {
          params: {
            type: 2,
            id: classid,
          },
        })
        .then((res) => {
          res.data.obj.two.unshift({
            id: 0,
            categoryCnname: "全部",
          });
          children.value = res.data.obj.two;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function onClickSecond(id: string, type: number) {
      router.push({
        name: "product",
        query: {
          type,
          id,
        },
      });
    }
    return {
      list,
      children,
      onClick,
      onClickSecond,
    };
  },
});
</script>


<style lang="scss" scoped>
.title {
  color: #333;
  text-align: center;
  font-size: 16px;
  line-height:1.5;
  padding: 7px 0;
  border-bottom: 1px solid #f4f4f4;
}
</style>