<template>
  <div class="container" :style="{ height: height }">
    <ul class="product-wapper">
      <li
        class="product-item"
        v-for="item in list"
        @click="onClick(item.categoryCode)"
        :key="item.id"
        :class="active == item.categoryCode ? 'active' : ''"
      >
        {{ item.categoryCnname }}
      </li>
    </ul>
    <ul class="second-class-wapper">
      <li
        class="second-class-item"
        v-for="item in children"
        :key="item.id"
        @click="onSecond(item.categoryCode)"
      >
        {{ item.categoryCnname }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import $ from "jquery";
export default defineComponent({
  name: "ProCateList",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    children: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["handleclick", "handlesecclick"],
  setup(props, ctx) {
    const height = ref("0px");
    onMounted(() => {
      height.value =
        Number($(window).height()) -
        Number($("header").height()) -
        Number($(".home-wapper .title").height()) -
        Number($("footer").height()) +
        "px";
    });
    const active = ref(0);
    watch(
      () => {
        return props.list;
      },
      (list) => {
        active.value = (list as any)[0].categoryCode;
      }
    );

    function onClick(id: string) {
      active.value = Number(id);
      ctx.emit("handleclick", id);
    }
    function onSecond(classid: string) {
      let type = 2;
      if (!classid) {
        classid = String(active.value);
        type = 0;
      }

      ctx.emit("handlesecclick", classid, type);
    }

    return {
      height,
      onClick,
      onSecond,
      active,
    };
  },
});
</script>


<style lang="scss" scoped>
.container {
  width: 100%;
  padding-left: 7em;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  padding-bottom: 20px;

  .product-wapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 7em;
    background-color: #f5f5f5;
    height: 100%;
    font-size: 14px;
    overflow-y: auto;
    padding-bottom: 40px;
    box-sizing: border-box;
    .product-item {
      width: 100%;
      padding-left: 7px;
      box-sizing: border-box;
      line-height: 50px;
      overflow: hidden;
      color: #656565;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .active {
      color: #df0024;
      border-left: 3px solid;
      background-color: #fff;
    }
  }
  .second-class-wapper {
    width: 100%;
    padding: 0 15px;
    height: 100%;
    overflow-y: auto;
    .second-class-item {
      width: 100%;
      padding-left: 7px;
      box-sizing: border-box;
      line-height: 50px;
      overflow: hidden;
      color: #262626;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>